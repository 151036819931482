<template>
<div v-if="!init" class="flex h-full justify-center items-center py-60">
    <n-spin size="small" />
</div>
<section v-else class="px-3">
    <n-alert
        v-if="volatility.localization['volatilityindicators_info']"
        class="rounded-md" type="warning" size="large">
        <div
            class="notes text-[10px]"
            v-html="volatility.localization['volatilityindicators_info']">
        </div>
    </n-alert>

    <super-table
        :filters="filters"
        :refFilters="refFilters"
        :pager="volatility.pager"
        :loading="loadings.table"
        :columns="volatility.columns"
        :records="volatility.records"
        :filtersChanged="filtersChanged"
        type="volatility-indicators"
        columnsStorageName="__columns_volatility_indicators"
        @getData="volatilityGet"
        @reset-filters="resetFilters"
        @applyFilters="changeFilter">
    </super-table>
</section>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled } from '@vicons/fluent';

// UI
import {
    NSpin,
    NCard,
    NIcon,
    NAlert,
    NButton,
    NDivider,
    NDataTable } from 'naive-ui';

// components
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import Progress from '@components/base/progress.vue';
import SuperTable from '@components/super-table/mobile.vue';

export default {
    name: 'tools-volatility-mobile',
    components: {
        NSpin,
        NCard,
        NIcon,
        NAlert,
        NButton,
        RbSelect,
        Progress,
        NDivider,
        SuperTable,
        NDataTable,
        RbCheckbox,
        Warning24Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(b) {
  color: #4949D9;
}

.table-card :deep(.n-card__content) {
  padding: 0;
}
</style>